<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="APP名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                      initialValue: this.detail.name,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

                <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="版本">
              <a-input
              placeholder='请输入1.0.0这种格式'
                v-decorator="[
                  'version',
                  {
                       initialValue: this.detail.version,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>


                     <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="ios链接">
              <a-input
                v-decorator="[
                  'iosUrl',
                  {
                       initialValue: this.detail.iosUrl,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="Android安装包">
              <FileUpload @uploaded="uploadedAndroid">
                <a-button type="primary">
                  {{ androidUrl ? "已上传" : "上传" }}
                </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>

                  <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="Windows安装包">
              <FileUpload @uploaded="uploadedWindows">
                <a-button type="primary">
                  {{ windowsUrl ? "已上传" : "上传" }}
                </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>

                  <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="macOS安装包">
              <FileUpload @uploaded="uploadedMacOS">
                <a-button type="primary">
                  {{ macOsUrl ? "已上传" : "上传" }}
                </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>


                  <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="harmonyOS安装包">
              <FileUpload @uploaded="uploadedHarmonyOS">
                <a-button type="primary">
                  {{ harmonyOsUrl ? "已上传" : "上传" }}
                </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>

                     <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注"
                    :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                v-decorator="[
                  'remark',
                  {
                       initialValue: this.detail.remark,

                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>


        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading='loading'>保存</a-button>
            <a-button @click="$close($route.androidUrl)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { edit } from "@/api/setting/ai-version";

export default {

  components: {
    FileUpload,
  },

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      androidUrl: '',
      windowsUrl: '',
      macOsUrl: '',
      harmonyOsUrl: '',
      loading: false,
    };
  },

  mounted() {
    const str = window.localStorage.getItem('editingAIApp')
    if (str) {
      this.detail = JSON.parse(str)
      this.androidUrl =  this.detail.androidUrl;
      this.windowsUrl =  this.detail.windowsUrl;
      this.macOsUrl =  this.detail.macOsUrl;
      this.harmonyOsUrl =  this.detail.harmonyOsUrl;
    }
  },

  methods: {
    uploadedAndroid(list) {
      if (list.length > 0) {
            this.androidUrl =  list[0].completePath;
      }
    },
        uploadedWindows(list) {
      if (list.length > 0) {
            this.windowsUrl =  list[0].completePath;
      }
    },
        uploadedMacOS(list) {
      if (list.length > 0) {
            this.macOsUrl =  list[0].completePath;
      }
    },
        uploadedHarmonyOS(list) {
      if (list.length > 0) {
            this.harmonyOsUrl =  list[0].completePath;
      }
    },

    handleSubmit(e) {
      e.preventDefault();

      if (!this.androidUrl) {
        this.$message.error("请上传Android安装包");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          edit({
            ...this.detail,
            ...values,
                 androidUrl: this.androidUrl,
            windowsUrl: this.windowsUrl,
            macOsUrl: this.macOsUrl,
            harmonyOsUrl: this.harmonyOsUrl,
          }).then(() => {
            this.$close(this.$route.androidUrl);
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
